var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mom-page',{attrs:{"title":"Registration e-Service","show-breadcrumb":"","breadcrumb":[
    {
      text: 'myMomPortal  ',
      href: '#',
    },
    {
      text: 'Request Form',
      href: '#',
    } ]}},[_c('mom-page-resp-header',{attrs:{"slot":"header","user-name":"User Name","allow-login":"","googleAnalyticsDetailsLogin":[
      {
        gEventName: 'LogIn',
        gtagId: "LoginButton",
        gtagIsEvent: true,
        gtagIsException: false,
        gtagEventCategory: 'User Login',
        gtagEventLabel: 'User Login',
        gtagCustomIdentifier: 'User Login',
      } ],"googleAnalyticsDetails":[
      {
        gEventName: 'Acknowledgement',
        gtagId: "Acknowledgement Loaded",
        gtagIsEvent: true,
        gtagIsException: false,
        gtagEventCategory: 'Acknowledgement Loaded',
        gtagEventLabel: 'Acknowledgement Loaded',
        gtagCustomIdentifier: 'Acknowledgement Loaded',
      } ]},slot:"header"}),_c('mom-layout-wrapper',{staticClass:"MomAcknowledgementPageDxd__Wrapper"},[_c('div',{staticClass:"MomAcknowledgementPageDxd__PreCard"},[_c('mom-banner',{attrs:{"type":"success","variant":"page-banner","title":"SUCCESS","size":"full"}},[_c('p',[_vm._v("Your form has been submitted")])])],1),_c('mom-card',{staticClass:"MomAcknowledgementPageDxd__ContentCard"},[_c('h3',{staticClass:"mom-h3"},[_vm._v("Application details")]),_c('div',[_c('mom-description-item',{attrs:{"term":"Acknowledgement Number:","term-width":"l","details":"# XXXX XXXX"}}),_c('mom-description-item',{attrs:{"term":"Transaction Summary","term-width":"l","details":"List of products/services transacted"}}),_c('mom-description-item',{attrs:{"term":"Transaction Date & Time","term-width":"l","details":"20/12/2020 at 14:27"}}),_c('mom-description-item',{attrs:{"term":"Payment Mode","term-width":"l","details":"Online Transfer"}}),_c('mom-description-item',{attrs:{"term":"Amount Paid","term-width":"l","details":"$120.00"}})],1),_c('mom-grid-container',[_c('mom-grid-column',[_c('div',{staticClass:"MomAcknowledgementPageDxd__Links"},[_c('mom-link',{staticClass:"MomAcknowledgementPageDxd__Link",attrs:{"text":"Download (pdf,90kb)","type":"button","icon":"download","googleAnalyticsDetails":[
                {
                  gEventName: 'FeatureUse',
                  gtagId: "DownloadAck",
                  gtagIsEvent: true,
                  gtagIsException: false,
                  gtagEventCategory: 'FeatureUse',
                  gtagEventLabel: 'Download Ack',
                  gtagCustomIdentifier: 'Download Ack',
                } ]},on:{"click":_vm.onDownload}}),_c('mom-link',{staticClass:"MomAcknowledgementPageDxd__Link",attrs:{"text":"Print","type":"button","icon":"print","googleAnalyticsDetails":[
                {
                  gEventName: 'FeatureUse',
                  gtagId: "PrintAck",
                  gtagIsEvent: true,
                  gtagIsException: false,
                  gtagEventCategory: 'FeatureUse',
                  gtagEventLabel: 'Print Ack',
                  gtagCustomIdentifier: 'Print Ack',
                } ]},on:{"click":_vm.onPrint}}),_c('mom-link',{staticClass:"MomAcknowledgementPageDxd__Link",attrs:{"text":"Email","type":"button","icon":"document-upload","googleAnalyticsDetails":[
                {
                  gEventName: 'FeatureUse',
                  gtagId: "EmailAck",
                  gtagIsEvent: true,
                  gtagIsException: false,
                  gtagEventCategory: 'FeatureUse',
                  gtagEventLabel: 'Email Ack',
                  gtagCustomIdentifier: 'Email Ack',
                } ]},on:{"click":_vm.onEmail}})],1)])],1)],1),(_vm.actions.length > 0)?_c('mom-card',{staticClass:"MomAcknowledgementPageDxd__ActionCard"},[_c('h3',{staticClass:"mom-h3"},[_vm._v("Next Steps")]),_c('ul',{staticClass:"mom-ul"},_vm._l((_vm.actions),function(action,i){return _c('li',{key:i},[(action.href)?_c('mom-link',{attrs:{"display":"inline","text":action.text,"href":action.href,"target":action.target || '_blank',"rel":action.rel}}):_c('p',[_vm._v(_vm._s(action.text))])],1)}),0)]):_vm._e(),(_vm.actions.length > 0)?_c('mom-card',{staticClass:"MomAcknowledgementPageDxd__RelatedActionCard"},[_c('h3',{staticClass:"mom-h3"},[_vm._v("Related Content")]),_c('ul',{staticClass:"mom-ul"},_vm._l((_vm.related_actions),function(action,i){return _c('li',{key:i},[(action.href)?_c('mom-link',{attrs:{"display":"inline","text":action.text,"href":action.href,"target":action.target || '_blank',"rel":action.rel}}):_c('p',[_vm._v(_vm._s(action.text))])],1)}),0)]):_vm._e()],1),_c('mom-page-footer',{attrs:{"slot":"footer"},slot:"footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }