<template>
  <mom-page
    title="Registration e-Service"
    show-breadcrumb
    :breadcrumb="[
      {
        text: 'myMomPortal  ',
        href: '#',
      },
      {
        text: 'Request Form',
        href: '#',
      },
    ]"
  >
    <mom-page-resp-header
      slot="header"
      user-name="User Name"
      allow-login
      :googleAnalyticsDetailsLogin="[
        {
          gEventName: 'LogIn',
          gtagId: `LoginButton`,
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: 'User Login',
          gtagEventLabel: 'User Login',
          gtagCustomIdentifier: 'User Login',
        },
      ]"
      :googleAnalyticsDetails="[
        {
          gEventName: 'Acknowledgement',
          gtagId: `Acknowledgement Loaded`,
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: 'Acknowledgement Loaded',
          gtagEventLabel: 'Acknowledgement Loaded',
          gtagCustomIdentifier: 'Acknowledgement Loaded',
        },
      ]"
    />
    <mom-layout-wrapper class="MomAcknowledgementPageDxd__Wrapper">
      <div class="MomAcknowledgementPageDxd__PreCard">
        <mom-banner
          type="success"
          variant="page-banner"
          title="SUCCESS"
          size="full"
        >
          <p>Your form has been submitted</p>
        </mom-banner>
      </div>
      <mom-card class="MomAcknowledgementPageDxd__ContentCard">
        <h3 class="mom-h3">Application details</h3>
        <div>
          <mom-description-item
            term="Acknowledgement Number:"
            term-width="l"
            details="# XXXX XXXX"
          />
          <mom-description-item
            term="Transaction Summary"
            term-width="l"
            details="List of products/services transacted"
          />
          <mom-description-item
            term="Transaction Date & Time"
            term-width="l"
            details="20/12/2020 at 14:27"
          />
          <mom-description-item
            term="Payment Mode"
            term-width="l"
            details="Online Transfer"
          />
          <mom-description-item
            term="Amount Paid"
            term-width="l"
            details="$120.00"
          />
        </div>

        <mom-grid-container>
          <mom-grid-column>
            <div class="MomAcknowledgementPageDxd__Links">
              <mom-link
                text="Download (pdf,90kb)"
                class="MomAcknowledgementPageDxd__Link"
                type="button"
                icon="download"
                @click="onDownload"
                :googleAnalyticsDetails="[
                  {
                    gEventName: 'FeatureUse',
                    gtagId: `DownloadAck`,
                    gtagIsEvent: true,
                    gtagIsException: false,
                    gtagEventCategory: 'FeatureUse',
                    gtagEventLabel: 'Download Ack',
                    gtagCustomIdentifier: 'Download Ack',
                  },
                ]"
              />
              <mom-link
                text="Print"
                class="MomAcknowledgementPageDxd__Link"
                type="button"
                icon="print"
                @click="onPrint"
                :googleAnalyticsDetails="[
                  {
                    gEventName: 'FeatureUse',
                    gtagId: `PrintAck`,
                    gtagIsEvent: true,
                    gtagIsException: false,
                    gtagEventCategory: 'FeatureUse',
                    gtagEventLabel: 'Print Ack',
                    gtagCustomIdentifier: 'Print Ack',
                  },
                ]"
              />
              <mom-link
                text="Email"
                class="MomAcknowledgementPageDxd__Link"
                type="button"
                icon="document-upload"
                @click="onEmail"
                :googleAnalyticsDetails="[
                  {
                    gEventName: 'FeatureUse',
                    gtagId: `EmailAck`,
                    gtagIsEvent: true,
                    gtagIsException: false,
                    gtagEventCategory: 'FeatureUse',
                    gtagEventLabel: 'Email Ack',
                    gtagCustomIdentifier: 'Email Ack',
                  },
                ]"
              />
            </div>
          </mom-grid-column>
        </mom-grid-container>
      </mom-card>

      <mom-card
        v-if="actions.length > 0"
        class="MomAcknowledgementPageDxd__ActionCard"
      >
        <h3 class="mom-h3">Next Steps</h3>
        <ul class="mom-ul">
          <li v-for="(action, i) in actions" :key="i">
            <mom-link
              v-if="action.href"
              display="inline"
              :text="action.text"
              :href="action.href"
              :target="action.target || '_blank'"
              :rel="action.rel"
            />
            <p v-else>{{ action.text }}</p>
          </li>
        </ul>
      </mom-card>

      <mom-card
        v-if="actions.length > 0"
        class="MomAcknowledgementPageDxd__RelatedActionCard"
      >
        <h3 class="mom-h3">Related Content</h3>
        <ul class="mom-ul">
          <li v-for="(action, i) in related_actions" :key="i">
            <mom-link
              v-if="action.href"
              display="inline"
              :text="action.text"
              :href="action.href"
              :target="action.target || '_blank'"
              :rel="action.rel"
            />
            <p v-else>{{ action.text }}</p>
          </li>
        </ul>
      </mom-card>
    </mom-layout-wrapper>
    <mom-page-footer slot="footer" />
  </mom-page>
</template>

<script>
import {
  MomPageRespHeader,
  MomPageFooter,
} from "../../dxplus_release/components/page.esm";
import { MomPage } from "../../dxplus_release/template.esm";
import { MomCard } from "../../dxplus_release/components/mom_card.esm";
import {
  MomGridContainer,
  MomGridColumn,
} from "../../dxplus_release/components/gridsystem.esm";
import { MomLayoutWrapper } from "../../dxplus_release/components/layout.esm";
import { MomLink } from "../../dxplus_release/components/link.esm";
import { MomInputCheckboxButton } from "../../dxplus_release/components/input_checkbox.esm";
import { MomButton } from "../../dxplus_release/components/button.esm";
import { MomBanner } from "../../dxplus_release/components/banner.esm";
import { MomDescriptionItem } from "../../dxplus_release/components/description.esm";

export default {
  name: "DxERegistrationForm",
  components: {
    MomPage,
    MomPageRespHeader,
    MomPageFooter,
    MomLayoutWrapper,
    MomCard,
    MomGridContainer,
    MomGridColumn,
    MomLink,
    MomInputCheckboxButton,
    MomButton,
    MomBanner,
    MomDescriptionItem,
  },
  data() {
    return {
      actions: [
        {
          text: "Make some minor changes as needed",
        },
        {
          text: "Contact here for further assistance",
          href: "#",
        },
      ],
      related_actions: [
        {
          text: "Related link one",
          href: "#",
        },
        {
          text: "Related link two",
          href: "#",
        },
      ],
    };
  },
  methods: {
    onDownload() {
      alert("Please save file.");
    },
    onPrint() {
      alert("This is printed ack.");
    },
    onEmail() {
      alert("Ack Emailed.");
    },
  },
};
</script>

<style lang="scss" scoped>
$margin-bottom-card: 2rem;
.MomAcknowledgementPageDxd__Wrapper {
  margin-top: 2rem;
}

.MomAcknowledgementPageDxd__ContentCard {
  min-height: 10rem;
}

.MomAcknowledgementPageDxd__PreCard {
  margin-bottom: 2rem;
}

.MomAcknowledgementPageDxd__Header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.MomAcknowledgementPageDxd__Title {
  margin-bottom: 0;
}

.MomAcknowlegdementPageDxd__Links {
  display: flex;
  flex-direction: column;
}

.MomAcknowledgementPageDxd__RelatedActionCard {
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 5rem;
}
.MomAcknowledgementPageDxd__Links {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}

.MomAcknowledgementPageDxd__Link {
  padding-right: 3rem;
  margin-top: 0rem;

  &:first-child {
    margin-top: 0;
  }
}
</style>
